import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Internet_Access/Port_Forwarding/Telekom_Speedport/YoutubeVideoCardForwarding";
import ConfigTable01 from "components/Internet_Access/Port_Forwarding/Telekom_Speedport/ConfigTable01";
import ConfigTable02 from "components/Internet_Access/Port_Forwarding/Telekom_Speedport/ConfigTable02";
import DangerBox from 'components/Internet_Access/Port_Forwarding/Telekom_Speedport/DangerBox';
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding - Telekom Speedport",
  "path": "/Internet_Access/Port_Forwarding/Telekom_Speedport/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding_Speedport.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_Speedport.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='Port Forwarding - Telekom Speedport' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding_Speedport.png' twitter='/images/Search/IA_SearchThumb_Portforwarding_Speedport.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/Telekom_Speedport/' locationFR='/fr/Internet_Access/Port_Forwarding/Telekom_Speedport/' crumbLabel="Telekom Speedport" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "tp-link-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tp-link-router",
        "aria-label": "tp link router permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TP-Link Router`}</h2>
    <p>{`This is the 2nd step of the Port Forwarding, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`here`}</a>{` to get to the beginning of the manual. There are currently three different Speedport WebUIs in use, please find yours below:`}</p>
    <h2 {...{
      "id": "telekom-speedport-hybrid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#telekom-speedport-hybrid",
        "aria-label": "telekom speedport hybrid permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Telekom Speedport Hybrid`}</h2>
    <p>{`Open the WebUI of your Speedport router by typing its IP-Address in your browser (by default: 192.168.2.1). (The default password of the router you can find on the sticker on its backside.)`}</p>
    <p>{`In the menu choose `}<strong parentName="p">{`Internet`}</strong>{` and then `}<strong parentName="p">{`Portfreischaltung`}</strong>{`. Open the menu under `}<strong parentName="p">{`Port-Umleitungen und Port-Weiterleitungen`}</strong>{`, there you apply the following settings:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable01 mdxType="ConfigTable01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7c1aaf018f215f8107de60722ff79e22/bb27a/Speedport2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABr0lEQVQoz3WQXY/SQBSG+2vVC1wIH5vAXrl/Rm5MJC10haDEeOGdicZkuyC43ba0pe208z3MTMfAbgyS9bk6b3KevCfHWv24fT98d/PhZjwe27Y9nU4Xi8W973ue505cx3Fc153NZqPRyLbtyWTiOM5yuTTG1HVtfVx8evHqZafbbTabry8u+ldXb66vbWf8djjsXl62Wq1Op9vv9xuNRq/XGwwG7XZ7Pp8bY7TWlhISpQVF+JAZA3FSplkWRWiX1UIcGkxtjBF7ChFUSpkTLIbpduXvtjGhhCUpWG3K9W+w9mNvnd2HkFCAcEUYJGWSxghhfeRJFnuRg6KClTFGKlVBKLXWQrCiLMMYBFsYJYUfsAoSjBnn/zSfBsF54D/ESarygt/98uaLzZev5U9v9+07DmLC2fnZp0FKmWc5wthIKSAsgpDuMhBE2yBEyzj/fEcIkUeekesjh4lzHIX5ckM2D+Xtmm0TDkkVZQghIcTf/v/IxgilMMA0h7JiRtaPPz/jXFZK6ScUhJAQghACANT6Gd2qT1BKSURVRRVksqRsVwlI93IvONdan24+yn8AR6hXq2In8qEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c1aaf018f215f8107de60722ff79e22/e4706/Speedport2.avif 230w", "/en/static/7c1aaf018f215f8107de60722ff79e22/d1af7/Speedport2.avif 460w", "/en/static/7c1aaf018f215f8107de60722ff79e22/7f308/Speedport2.avif 920w", "/en/static/7c1aaf018f215f8107de60722ff79e22/b0250/Speedport2.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7c1aaf018f215f8107de60722ff79e22/a0b58/Speedport2.webp 230w", "/en/static/7c1aaf018f215f8107de60722ff79e22/bc10c/Speedport2.webp 460w", "/en/static/7c1aaf018f215f8107de60722ff79e22/966d8/Speedport2.webp 920w", "/en/static/7c1aaf018f215f8107de60722ff79e22/f16bf/Speedport2.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7c1aaf018f215f8107de60722ff79e22/81c8e/Speedport2.png 230w", "/en/static/7c1aaf018f215f8107de60722ff79e22/08a84/Speedport2.png 460w", "/en/static/7c1aaf018f215f8107de60722ff79e22/c0255/Speedport2.png 920w", "/en/static/7c1aaf018f215f8107de60722ff79e22/bb27a/Speedport2.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7c1aaf018f215f8107de60722ff79e22/c0255/Speedport2.png",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`By clicking on `}<strong parentName="p">{`speichern`}</strong>{`, the settings will be applied.`}</p>
    <p>{`To be able to access the Flash-Video as well, there has to be set a second Port Forwarding. Therefore, just follow the manual of this step, with the only difference to set the Flash-Port instead. So for your first camera all ports are set to 1935, for your second camera it´s 1936, etc.`}</p>
    <p>{`Now all Port Forwardings for your camera are set in the router. You can now continue with the next step, therefore follow this Link:`}</p>
    <p><strong parentName="p">{`Attention`}</strong>{`:`}</p>
    <p>{`Port forwarding can be tested, for example, with the Fritzbox from your own network. However, there are routers that do not support access via DDNS address on the computer in the local network (some models of the `}<strong parentName="p">{`Telekom Speedport`}</strong>{`). This means that you may have to try access via a computer in another network, alternatively you can also test it with your smartphone via the mobile network.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "telekom-speedport-300--500--and-700-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#telekom-speedport-300--500--and-700-series",
        "aria-label": "telekom speedport 300  500  and 700 series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Telekom Speedport (300-, 500- and 700-series)`}</h2>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Open the WebUI of your Speedport router by typing its IP-Address in your browser (by default: 192.168.2.1). (The default password of the router you can find on the sticker on its backside.)`}</p>
    <p>{`Then in the menu head to `}<strong parentName="p">{`Konfiguration / Netzwerk`}</strong>{` and in the following window to `}<strong parentName="p">{`NAT&Portregeln`}</strong>{`. Fill in the blankets as follows:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable02 mdxType="ConfigTable02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fa30e63a956e604bf64897e9f374ceca/2f5f2/Speedport1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAF/N2hIyH//xAAaEAEAAgMBAAAAAAAAAAAAAAACAQMAERIT/9oACAEBAAEFAlSJJoPXjXkmNQRvP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB4QAAIBAwUAAAAAAAAAAAAAAAACARAhMSIycYGR/9oACAEBAAY/AsKvRGpODYvhcjNP/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAERITFRYfH/2gAIAQEAAT8hUrU6JpNeFp5MegtdMWcrzyM//9oADAMBAAIAAwAAABBzD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAwADAAAAAAAAAAAAAAEAESExUUFx8P/aAAgBAQABPxBHyjQWc8QZKGQH03K8LfXIRcBsvuaWwl43Np//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa30e63a956e604bf64897e9f374ceca/e4706/Speedport1.avif 230w", "/en/static/fa30e63a956e604bf64897e9f374ceca/d1af7/Speedport1.avif 460w", "/en/static/fa30e63a956e604bf64897e9f374ceca/48116/Speedport1.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fa30e63a956e604bf64897e9f374ceca/a0b58/Speedport1.webp 230w", "/en/static/fa30e63a956e604bf64897e9f374ceca/bc10c/Speedport1.webp 460w", "/en/static/fa30e63a956e604bf64897e9f374ceca/9eee1/Speedport1.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa30e63a956e604bf64897e9f374ceca/e83b4/Speedport1.jpg 230w", "/en/static/fa30e63a956e604bf64897e9f374ceca/e41a8/Speedport1.jpg 460w", "/en/static/fa30e63a956e604bf64897e9f374ceca/2f5f2/Speedport1.jpg 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fa30e63a956e604bf64897e9f374ceca/2f5f2/Speedport1.jpg",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To be able to access the Flash-Video as well, there has to be set a second Port Forwarding. Therefore, just follow the manual of this step, with the only difference to set the Flash-Port instead. So for your first camera all ports are set to 1935, for your second camera it´s 1936, etc.`}</p>
    <p>{`Now all Port Forwardings for your camera are set in the router. You can now continue with the next step, therefore follow this Link:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Exception for W500V`}</strong>{`: In the menu `}<em parentName="p">{`NAT & Portregeln`}</em>{` add your camera´s MAC-address. (You can find the camera´s MAC-address in its WebUI under System > Info)`}</p>
    <p><strong parentName="p">{`Caution:`}</strong></p>
    <p>{`Whereas it is possible to test the DDNS access in a FritzBox of the same network, there are routers such as the Telekom Speedports which can deny the test. This means you would have to use a computer in another network, or alternatively test the DDNS-Access by using the mobile network on your smartphone.`}</p>
    <h2 {...{
      "id": "telekom-speedport-900-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#telekom-speedport-900-series",
        "aria-label": "telekom speedport 900 series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Telekom Speedport (900 series)`}</h2>
    <p>{`Open the WebUI of your Speedport router by typing its IP-Address in your browser (by default: 192.168.2.1). (The default password of the router you can find on the sticker on its backside.)`}</p>
    <p>{`Then head to `}<strong parentName="p">{`Internet`}</strong>{` and `}<strong parentName="p">{`Portfreischaltung`}</strong>{`. Click under `}<strong parentName="p">{`Port-Umleitungen und Port-Weiterleitungen`}</strong>{` under `}<strong parentName="p">{`TCP Umleitung`}</strong>{` on `}<strong parentName="p">{`Weitere TCP Umleitung anlegen`}</strong>{` and choose the IP-address of your camera in the drop-down menu. In the three arrays before the IP-address enter the port of your camera, which is three times `}<em parentName="p">{`8081`}</em>{` for your first camera. For your second camera you enter `}<em parentName="p">{`8082`}</em>{` etc. Then click on `}<strong parentName="p">{`Speichern`}</strong>{` to apply the settings.`}</p>
    <p>{`To be able to access the Flash-Video as well, there has to be set a second Port Forwarding. Therefore, just follow the manual of this step, with the only difference to set the Flash-Port instead. So for your first camera all ports are set to `}<em parentName="p">{`1935`}</em>{`, for your second camera it´s `}<em parentName="p">{`1936`}</em>{`, etc.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a0ae340406c42b44571d77579e9878db/7cc5e/Speedport_01en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHVLECh4f/EABgQAAIDAAAAAAAAAAAAAAAAAAATARAR/9oACAEBAAEFAli9FE3/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAMBAQAAAAAAAAAAAAAAAAIykgAg/9oACAEBAAY/AmKsxVmKuP/EABsQAQACAgMAAAAAAAAAAAAAAAEAMRHRECFB/9oACAEBAAE/Ieplv8Uw2UAbUpLTj//aAAwDAQACAAMAAAAQYM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAwACAwAAAAAAAAAAAAABABFBECExUXH/2gAIAQEAAT8Q7wjihL11r6AhVXv0nTfnIAhLcvOP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0ae340406c42b44571d77579e9878db/e4706/Speedport_01en.avif 230w", "/en/static/a0ae340406c42b44571d77579e9878db/d1af7/Speedport_01en.avif 460w", "/en/static/a0ae340406c42b44571d77579e9878db/884e7/Speedport_01en.avif 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a0ae340406c42b44571d77579e9878db/a0b58/Speedport_01en.webp 230w", "/en/static/a0ae340406c42b44571d77579e9878db/bc10c/Speedport_01en.webp 460w", "/en/static/a0ae340406c42b44571d77579e9878db/d3be9/Speedport_01en.webp 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0ae340406c42b44571d77579e9878db/e83b4/Speedport_01en.jpg 230w", "/en/static/a0ae340406c42b44571d77579e9878db/e41a8/Speedport_01en.jpg 460w", "/en/static/a0ae340406c42b44571d77579e9878db/7cc5e/Speedport_01en.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a0ae340406c42b44571d77579e9878db/7cc5e/Speedport_01en.jpg",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now all Port Forwardings for your camera are set in the router. You can now continue with the next step, therefore follow this Link:`}</p>
    <p>{`Step III: Reading the camera address and remote access to the camera`}</p>
    <h2 {...{
      "id": "step-iii-the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-iii-the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III: The Camera´s DDNS-Address and the Remote-Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "attention",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#attention",
        "aria-label": "attention permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Attention`}</h3>
    <p>{`While it is possible to test the DDNS access in a FritzBox of the same network, there are routers like the Telekom Speedports that can refuse the test. This means that you have to use a computer in another network or alternatively test the DDNS access by using the mobile network on your smartphone.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      